.container {
  margin: 2rem 0;
}

ul.nav {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  li {
    list-style: none;
    margin: 1rem 0.4rem;

    button {
      display: block;
      cursor: pointer;
      outline: none;

      font-family: serif;
      font-weight: bold;
      font-style: italic;
      font-size: 1rem;

      padding: 0.6rem 0.8em;

      background: white;
      border: 1px solid #ddd;
      box-shadow: 4px 4px 0 #ddd;

      transition: all 0.1s ease;
      transform: translate(-2px, -2px);

      &:hover {
        transform: translate(-2.5px, -2.5px);
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3), 4.5px 4.5px 0 #009688;
        border-color: #009688;
      }

      &.selected {
        transform: translate(-1px, -1px);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3),
          2px 2px 0 darken(#009688, 10%);
        border-color: darken(#009688, 10%);
      }

      &:active,
      &.selected:active {
        transform: translate(0px, 0px);
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3),
          1px 1px 0 darken(#009688, 10%);
        border-color: darken(#009688, 10%);
      }
    }
  }
}

.content {
  text-align: center;
  max-width: 100%;
  overflow-x: auto;
}
