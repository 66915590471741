svg.railroad-diagram {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

svg.railroad-diagram path {
  stroke-width: 3;
  stroke: black;
  fill: rgba(0, 0, 0, 0);
}
svg.railroad-diagram text {
  font: bold 16px monospace;
  text-anchor: middle;
  white-space: pre;
  fill: white;
}
svg.railroad-diagram .non-terminal text {
  font-family: serif;
  font-weight: bold;
  font-style: italic;
}
svg.railroad-diagram text.diagram-text {
  font-size: 12px;
}
svg.railroad-diagram text.diagram-arrow {
  font-size: 16px;
}
svg.railroad-diagram text.label {
  text-anchor: start;
}
svg.railroad-diagram text.comment {
  font: italic 12px monospace;
  fill: black;
}
svg.railroad-diagram rect {
  stroke-width: 3;
  stroke: darken(#949494, 7%);
  fill: #949494;
}
svg.railroad-diagram .new,
.new svg.railroad-diagram {
  rect {
    stroke: darken(#009688, 5%);
    fill: #009688;
  }
}
svg.railroad-diagram path.diagram-text {
  stroke-width: 3;
  stroke: black;
  fill: white;
  cursor: help;
}
svg.railroad-diagram g.diagram-text:hover path.diagram-text {
  fill: #eee;
}
