@import url(https://fonts.googleapis.com/css?family=Fira+Mono&display=swap);
html,
body {
  font-family: serif;
  font-size: 18px;
  line-height: 140%; }

body {
  margin: 0;
  padding: 0;
  background: white; }

h1 {
  line-height: 150%; }

h2 {
  margin: 0;
  padding: 2rem 0 1rem 0; }
  h2 .LinkIcon {
    display: none; }
  h2:hover .LinkIcon {
    display: inline; }

p {
  margin: 1rem 0; }

.lead {
  font-size: 115%;
  line-height: 150%; }

.nt {
  color: white;
  background: #009688;
  border: 2px solid #007d71;
  font-weight: bold;
  padding: 0px 5px; }

a {
  color: #000; }

li {
  margin: 0.3rem 0; }

.wrap {
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 1.4rem; }

main {
  padding: 2rem 0 5rem; }
  main .titlecontainer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    padding: 1rem 0; }
    main .titlecontainer .titleblock {
      margin: 0 0 0 1.4rem; }

@media (max-width: 800px) {
  main {
    -ms-flex-direction: column;
        flex-direction: column; }
    main .sidebar {
      display: none; }
      main .sidebar.show {
        display: block; }
    main aside {
      margin: 0 1.4rem; }
    main .titlecontainer {
      -ms-flex-direction: column;
          flex-direction: column; }
      main .titlecontainer .titleblock {
        margin: 1rem 0 0; } }

footer {
  background: #007d71;
  color: #eee;
  padding: 2rem 0; }
  footer a {
    color: #eee; }
  footer code {
    background: #17524d; }

code {
  font-family: "Fira Mono", "Monaco", "Menlo", "DejaVu Sans Mono", "Consolas", monospace;
  font-size: 80%;
  background: #d6dfe0;
  padding: 1px 2px; }

svg.railroad-diagram {
  max-width: 100%;
  display: block;
  margin: 0 auto; }

svg.railroad-diagram path {
  stroke-width: 3;
  stroke: black;
  fill: rgba(0, 0, 0, 0); }

svg.railroad-diagram text {
  font: bold 16px monospace;
  text-anchor: middle;
  white-space: pre;
  fill: white; }

svg.railroad-diagram .non-terminal text {
  font-family: serif;
  font-weight: bold;
  font-style: italic; }

svg.railroad-diagram text.diagram-text {
  font-size: 12px; }

svg.railroad-diagram text.diagram-arrow {
  font-size: 16px; }

svg.railroad-diagram text.label {
  text-anchor: start; }

svg.railroad-diagram text.comment {
  font: italic 12px monospace;
  fill: black; }

svg.railroad-diagram rect {
  stroke-width: 3;
  stroke: #828282;
  fill: #949494; }

svg.railroad-diagram .new rect,
.new svg.railroad-diagram rect {
  stroke: #007d71;
  fill: #009688; }

svg.railroad-diagram path.diagram-text {
  stroke-width: 3;
  stroke: black;
  fill: white;
  cursor: help; }

svg.railroad-diagram g.diagram-text:hover path.diagram-text {
  fill: #eee; }

.Tabbed_container__20F7- {
  margin: 2rem 0; }

ul.Tabbed_nav__1yge6 {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center; }
  ul.Tabbed_nav__1yge6 li {
    list-style: none;
    margin: 1rem 0.4rem; }
    ul.Tabbed_nav__1yge6 li button {
      display: block;
      cursor: pointer;
      outline: none;
      font-family: serif;
      font-weight: bold;
      font-style: italic;
      font-size: 1rem;
      padding: 0.6rem 0.8em;
      background: white;
      border: 1px solid #ddd;
      box-shadow: 4px 4px 0 #ddd;
      -webkit-transition: all 0.1s ease;
      transition: all 0.1s ease;
      -webkit-transform: translate(-2px, -2px);
              transform: translate(-2px, -2px); }
      ul.Tabbed_nav__1yge6 li button:hover {
        -webkit-transform: translate(-2.5px, -2.5px);
                transform: translate(-2.5px, -2.5px);
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3), 4.5px 4.5px 0 #009688;
        border-color: #009688; }
      ul.Tabbed_nav__1yge6 li button.Tabbed_selected__1ofSX {
        -webkit-transform: translate(-1px, -1px);
                transform: translate(-1px, -1px);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), 2px 2px 0 #00635a;
        border-color: #00635a; }
      ul.Tabbed_nav__1yge6 li button:active, ul.Tabbed_nav__1yge6 li button.Tabbed_selected__1ofSX:active {
        -webkit-transform: translate(0px, 0px);
                transform: translate(0px, 0px);
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3), 1px 1px 0 #00635a;
        border-color: #00635a; }

.Tabbed_content__3KoXz {
  text-align: center;
  max-width: 100%;
  overflow-x: auto; }

/*
  This is an adaptation of the MDN-LIKE theme.
*/
/*
  MDN-LIKE Theme - Mozilla
  Ported to CodeMirror by Peter Kroon <plakroon@gmail.com>
  Report bugs/issues here: https://github.com/codemirror/CodeMirror/issues
  GitHub: @peterkroon

  The mdn-like theme is inspired on the displayed code examples at: https://developer.mozilla.org/en-US/docs/Web/CSS/animation

*/
.editable .cm-s-ljsx.CodeMirror {
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  box-shadow: 4px 4px 0 #dedcd9;
  border: 1px solid #ccc;
  background: #f9f9f9; }
  .editable .cm-s-ljsx.CodeMirror-focused {
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2), 4px 4px 0 #009688;
    border-color: #009688; }

.cm-s-ljsx {
  /*?*/
  /*?*/
  /*?*/
  /*?*/ }
  .cm-s-ljsx.CodeMirror {
    max-width: 100%;
    height: auto;
    color: #666;
    background: white;
    padding: 0.4rem;
    font-family: "Fira Mono", "Monaco", "Menlo", "DejaVu Sans Mono", "Consolas", monospace;
    font-size: 90%; }
  .cm-s-ljsx div.CodeMirror-selected {
    background: #d5d5d5; }
  .cm-s-ljsx .CodeMirror-line::-moz-selection,
  .cm-s-ljsx .CodeMirror-line > span::-moz-selection,
  .cm-s-ljsx .CodeMirror-line > span > span::-moz-selection {
    background: #d5d5d5; }
  .cm-s-ljsx .CodeMirror-line::selection,
  .cm-s-ljsx .CodeMirror-line > span::selection,
  .cm-s-ljsx .CodeMirror-line > span > span::selection {
    background: #d5d5d5; }
  .cm-s-ljsx .CodeMirror-line::-moz-selection,
  .cm-s-ljsx .CodeMirror-line > span::-moz-selection,
  .cm-s-ljsx .CodeMirror-line > span > span::-moz-selection {
    background: #d5d5d5; }
  .cm-s-ljsx .CodeMirror-gutters {
    background: #f8f8f8;
    color: #333; }
  .cm-s-ljsx .CodeMirror-linenumber {
    color: #aaa; }
  .cm-s-ljsx .CodeMirror-cursor {
    border-left: 2px solid #222; }
  .cm-s-ljsx .cm-keyword {
    color: #6262ff; }
  .cm-s-ljsx .cm-atom {
    color: #e91e63; }
  .cm-s-ljsx .cm-number {
    color: #da3024; }
  .cm-s-ljsx .cm-def {
    color: #8da6ce; }
  .cm-s-ljsx span.cm-variable-3,
  .cm-s-ljsx span.cm-def,
  .cm-s-ljsx span.cm-type {
    color: #07a; }
  .cm-s-ljsx .cm-variable {
    color: #07a; }
  .cm-s-ljsx .cm-property {
    color: #905; }
  .cm-s-ljsx .cm-qualifier {
    color: #690; }
  .cm-s-ljsx .cm-operator {
    color: #cda869; }
  .cm-s-ljsx .cm-comment {
    color: #777;
    font-weight: normal; }
  .cm-s-ljsx .cm-string {
    color: #07a; }
  .cm-s-ljsx .cm-string-2 {
    color: #bd6b18; }
  .cm-s-ljsx .cm-meta {
    color: #000; }
  .cm-s-ljsx .cm-builtin {
    color: #333; }
  .cm-s-ljsx .cm-tag {
    color: #007d71; }
  .cm-s-ljsx .cm-attribute {
    color: #555; }
  .cm-s-ljsx .cm-header {
    color: #ff6400; }
  .cm-s-ljsx .cm-hr {
    color: #aeaeae; }
  .cm-s-ljsx .cm-link {
    color: #ad9361;
    font-style: italic;
    text-decoration: none; }
  .cm-s-ljsx .cm-error {
    border-bottom: 1px solid red; }
  .cm-s-ljsxdiv .CodeMirror-activeline-background {
    background: #efefff; }
  .cm-s-ljsxdiv span.CodeMirror-matchingbracket {
    outline: 1px solid grey;
    color: inherit; }

