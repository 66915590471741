@import url("https://fonts.googleapis.com/css?family=Fira+Mono&display=swap");

html,
body {
  font-family: serif;
  font-size: 18px;
  line-height: 140%;
}

body {
  margin: 0;
  padding: 0;
  background: white;
}

h1 {
  line-height: 150%;
}

h2 {
  margin: 0;
  padding: 2rem 0 1rem 0;

  .LinkIcon {
    display: none;
  }

  &:hover .LinkIcon {
    display: inline;
  }
}

p {
  margin: 1rem 0;
}

.lead {
  font-size: 115%;
  line-height: 150%;
}

.nt {
  color: white;
  background: #009688;
  border: 2px solid #007d71;
  font-weight: bold;
  padding: 0px 5px;
}

a {
  color: #000;
}

li {
  margin: 0.3rem 0;
}

.wrap {
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 1.4rem;
}

main {
  padding: 2rem 0 5rem;

  .titlecontainer {
    display: flex;
    align-items: center;
    padding: 1rem 0;

    .titleblock {
      margin: 0 0 0 1.4rem;
    }
  }
}

@media (max-width: 800px) {
  main {
    flex-direction: column;
    .sidebar {
      display: none;

      &.show {
        display: block;
      }
    }
    aside {
      margin: 0 1.4rem;
    }

    .titlecontainer {
      flex-direction: column;

      .titleblock {
        margin: 1rem 0 0;
      }
    }
  }
}

footer {
  background: #007d71;
  color: #eee;
  padding: 2rem 0;

  a {
    color: #eee;
  }

  code {
    background: #17524d;
  }
}

code {
  font-family: "Fira Mono", "Monaco", "Menlo", "DejaVu Sans Mono", "Consolas",
    monospace;
  font-size: 80%;
  background: #d6dfe0;
  padding: 1px 2px;
}
